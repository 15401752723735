<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-08-30 19:26:24
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-09-12 16:32:14
 * @FilePath: \official_website\src\views\componets\Header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 页面头部 -->
<template>
  <div id="header">
    <div class="header">
      <div class="logo-box">
         <router-link to="/">
          <img src="@/assets/image/home/logo.png" alt="" />
         </router-link>
      </div>
      <ul class="navigators">
        <li>
          <router-link to="/">首页</router-link>
        </li>
        <li>
          <router-link to="/about">关于品信</router-link>
        </li>
        <li class="product">
          <div
            class="products"
            @mouseover="productMoveToggle = true"
            @mouseout="productMoveToggle = false"
          >
            <router-link to="/product" :class="tackleFlag ? 'higt-active' : ''" >产品及解决方案</router-link>
            <!-- v-show="productMoveToggle" -->
            <div v-show="productMoveToggle" class="products-list">
              <div class="left">
                <div
                  class="menu-tab-pane plans"
                  :class="planFlag ? 'solution-active' : ''"
                  @mouseenter="enterPlan"
                >
                  解决方案
                </div>
                <div
                  class="menu-tab-pane"
                  :class="productFlag ? 'solution-active' : ''"
                  @mouseenter="enterProduct"
                >
                  产品
                </div>
              </div>
              <div class="right">
                <ul class="solutions" ref="solutions" v-if="detailFlag">
                  <li  @mouseenter="enterSinglePlan">
                    <router-link to="/product/tbs" active-class="active"
                      >宫颈癌筛查解决方案</router-link
                    >
                  </li>
                  <li @mouseenter="enterSinglePlan">
                    <router-link to="/product/dna"
                      >DNA倍体癌症早筛解决方案</router-link
                    >
                  </li>
                  <li @mouseenter="enterSinglePlan">
                    <router-link to="/product/telepathology"
                      >远程病理诊断解决方案</router-link
                    >
                  </li>
                  <li @mouseenter="enterSinglePlan">
                    <router-link to="/product/blood"
                      >血液细胞形态诊断解决方案</router-link
                    >
                  </li>
                </ul>
                <div class="product-list" v-if="!detailFlag">
                  <ul class="product-left">
                    <li
                      :class="reagentSlot ? 'product-active' : ''"
                      @mouseenter="enterReagent"
                    >
                      诊断试剂包
                    </li>
                    <li
                      :class="digitSlot ? 'product-active' : ''"
                      @mouseenter="enterDigit"
                    >
                      数字切片扫描仪
                    </li>
                    <li
                      :class="filmSlot ? 'product-active' : ''"
                      @mouseenter="enterFilm"
                    >
                      液基细胞学前处理
                    </li>
                    <li
                      :class="bloodSlot ? 'product-active' : ''"
                      @mouseenter="enterBlood"
                    >
                      血液细胞形态
                    </li>
                    <li
                      :class="diagnosisSlot ? 'product-active' : ''"
                      @mouseenter="enterDiagnosis"
                    >
                      人工智能辅助诊断
                    </li>
                  </ul>
                  <div class="product-right">
                    <ul v-show="reagentFlag" @mouseenter="enterSinglePro('reagentSlot')">
                      <li>
                        <router-link to="/product/tctReagent"
                          >液基细胞学TCT诊断试剂</router-link>
                      </li>
                      <li>
                        <router-link to="/product/dnaReagent"
                          >DNA定量分析诊断试剂</router-link>
                      </li>
                    </ul>
                    <ul v-show="digitFlag" @mouseenter="enterSinglePro('digitSlot')">
                      <li>
                        <router-link to="/product/gemini"
                          >吉米娜系列</router-link
                        >
                      </li>
                      <!-- <li>
                        <router-link to="/product/smearmachine"
                          >120片机</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/product/smearmachine"
                          >30片机</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/product/smearmachine"
                          >3片机</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/product/smearmachine"
                          >2片机</router-link
                        >
                      </li> -->
                    </ul>
                    <ul v-show="filmFlag" @mouseenter="enterSinglePro('filmSlot')">
                      <li>
                        <router-link to="/product/pelleter"
                          >液基制片染色一体机</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/product/film"
                          >液基膜式制片机</router-link
                        >
                      </li>
                      <!-- <li>
                        <router-link to="/product/smearmachine"
                          >液基甩片制片机</router-link
                        >
                      </li> -->
                      <li>
                        <router-link to="/product/special"
                          >全自动特殊染色机</router-link
                        >
                      </li>
                    </ul>
                    <ul v-show="bloodFlag" @mouseenter="enterSinglePro('bloodSlot')">
                      <li>
                        <router-link to="/product/marrow"
                          >骨髓/血液细胞形态学AI扫描分析系统</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/product/bloodPushing"
                          >全自动推片染色机</router-link
                        >
                      </li>
                    </ul>
                    <ul v-show="diagnosisFlag" @mouseenter="enterSinglePro('diagnosisSlot')">
                      <li>
                        <router-link to="/product/dnaProd"
                          >DNA倍体定量分析系统</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/product/tctProd"
                          >细胞学TCT人工智能辅助诊断</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/product/patholog"
                          >远程病理会诊系统</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <router-link to="/medium" :class="mediumFlag ? 'higt-active' : ''">媒体中心</router-link>
        </li>
        <li>
          <router-link to="/science" :class="scienceFlag ? 'higt-active' : ''">学术园地</router-link>
        </li>
        <li>
          <router-link to="/contact">人才招募</router-link>
        </li>
        <li>
          <router-link to="/decennial">品信十周年</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      productMoveToggle: false,
      detailFlag: true,
      reagentFlag: false,
      digitFlag: false,
      filmFlag: false,
      bloodFlag: false,
      diagnosisFlag: false,
      planFlag: false,
      productFlag: false,

      reagentSlot: false,
      digitSlot: false,
      filmSlot: false,
      bloodSlot: false,
      diagnosisSlot: false,

      tackleFlag: false,
      mediumFlag: false,
      scienceFlag: false
    };
  },
  created() {},
  mounted() {
   
  },
  watch: {
    "$route.path": {
      // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        // console.log(to.path);
        switch (to) {
          case "/product/tbs":
            this.planFlag = true;
            break;
          case "/product/dna":
            this.planFlag = true;
            break;
          case "/product/telepathology":
            this.planFlag = true;
            break;
          case "/product/blood":
            this.planFlag = true;
            break;
          default:
            this.planFlag = false;
            break;
        }

        console.log(to);

        if(to.includes("product")) {
          this.tackleFlag = true
        } else if(to.includes("medium")) {
          this.mediumFlag = true
        } else if (to.includes("MediumDetail")) {
          this.mediumFlag = true
        } else if (to.includes("decipherDetail")) {
          this.scienceFlag = true
        } else if (to.includes("doctorDetail")) {
          this.scienceFlag = true
        } 
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    emptyHight() {
      this.reagentSlot = false;
      this.digitSlot = false;
      this.filmSlot = false;
      this.bloodSlot = false;
      this.diagnosisSlot = false;
    },
    enterPlan() {
      this.detailFlag = true;
      this.productFlag = false;
    },
    enterProduct() {
      this.detailFlag = false;
      this.planFlag = false;
    },
    enterSinglePlan() {
      this.planFlag = true;
    },
    enterReagent() {
      this.reagentFlag = true;
      this.digitFlag = false;
      this.filmFlag = false;
      this.bloodFlag = false;
      this.diagnosisFlag = false;
      this.productFlag = true;
      this.emptyHight()
    },
    enterDigit() {
      this.digitFlag = true;
      this.reagentFlag = false;
      this.filmFlag = false;
      this.bloodFlag = false;
      this.diagnosisFlag = false;
      this.productFlag = true;
      this.emptyHight()
    },
    enterFilm() {
      this.filmFlag = true;
      this.reagentFlag = false;
      this.digitFlag = false;
      this.bloodFlag = false;
      this.diagnosisFlag = false;
      this.productFlag = true;
      this.emptyHight()
    },
    enterBlood() {
      this.bloodFlag = true;
      this.reagentFlag = false;
      this.digitFlag = false;
      this.filmFlag = false;
      this.diagnosisFlag = false;
      this.productFlag = true;
      this.emptyHight()
    },
    enterDiagnosis() {
      this.diagnosisFlag = true;
      this.reagentFlag = false;
      this.digitFlag = false;
      this.filmFlag = false;
      this.bloodFlag = false;
      this.productFlag = true;
      this.emptyHight()
    },
    enterSinglePro(name) {
      this.emptyHight()
      switch (name) {
        case "reagentSlot":
          this.reagentSlot = true;
          break;
        case "digitSlot":
          this.digitSlot = true;
          break;
        case "filmSlot":
          this.filmSlot = true;
          break;
        case "bloodSlot":
          this.bloodSlot = true;
          break;
        case "diagnosisSlot":
          this.diagnosisSlot = true;
          break;
      }
    },
  },
};
</script>
    
<style lang="scss">
#header {
  .header {
    width: 100%;
    height: 5rem;
    padding: 0 3.125rem;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    .logo-box {
      img {
        height: 3.75rem;
      }
    }
    .navigators {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1.875rem;
      li {
        padding: 0 1.5rem;
        font-size: 1.25rem;
        font-weight: 500;
        font-family: "OPlusSans3-Medium";
        color: #212121;
        cursor: pointer;
        a {
          &.router-link-exact-active {
            color: #0b847b;
          }
        }
      }
      .product {
        .products {
          position: relative;
          display: inline-block;
          line-height: 5rem;
          .products-list {
            position: absolute;
            left: -50%;
            top: 5rem;
            width: 43.75rem;
            background-color: #fff;
            box-shadow: 0 0 10px 2px #ccc;
            display: flex;
            .left {
              width: 25%;
              padding: 1rem 0;
              border-right: 1px solid #ccc;
              .menu-tab-pane {
                padding: 0.625rem 1.25rem;
                line-height: 1.875rem;
                transition: all 0.3s;
                &:hover {
                  background-color: #0b847b;
                  color: #fff;
                }
              }
            }
            .right {
              flex: 1;
             
              ul {
                li {
                  padding: 0.625rem 1.2rem;
                  line-height: 1.875rem;
                  &:hover {
                    background-color: #0b847b;
                    color: #fff;
                    a {
                      color: #fff;
                    }
                  }
                }
              }
              .solutions {
                padding: 1rem 0;
              }

              .product-list {
                display: flex;
                .product-left {
                  width: 40%;
                  padding: 1rem 0;
                  border-right: 1px solid #ccc;
                }
                .product-right {
                  width: 60%;
                  padding: 1rem 0;
                  ul {
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
      // .nav-avtive {
      //   a {
      //     color: #0b847b !important;
      //   }
      // }
    }
  }
  .solution-active {
    background-color: #0b847b;
    color: #fff;
  }

  .higt-active {
    color: #0b847b;
  }

  .product-active {
    background-color: #0b847b;
    color: #fff!important;
  }
}
</style>